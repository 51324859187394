<template>
  <div>
    <a-modal
      title="订单跟踪"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="850px"
    >
      <div class="track-box">
        <div class="track-left">
          <div>
            <div style="width: 100%" class="pro-percent">
              <span style="color: #137ffd">▋</span> <span> 订单完成率 </span>
            </div>
            <a-progress
              type="circle"
              :percent="+config.data.orderRateVO.rate * 100"
              :strokeWidth="12"
              :width="190"
              :format="
                (percent) =>
                  `${(+config.data.orderRateVO.rate * 100).toFixed(2)}%已完成`
              "
            />
          </div>
          <div style="margin-top: 100px">
            <div style="width: 100%" class="pro-percent">
              <span style="color: #137ffd">▋</span> <span> 订单良品率 </span>
            </div>
            <a-progress
              type="circle"
              :percent="+config.data.timeDelivery * 100"
              :strokeWidth="12"
              :width="190"
              :format="(percent) => `${percent.toFixed(2)}%良品率`"
            />
          </div>
        </div>
        <div class="track-right">
          <div>
            <div class="datapick">
              <a-calendar
                :value="value"
                @select="onSelect"
                @panelChange="onPanelChange"
                :fullscreen="false"
              >
                <template slot="dateFullCellRender" slot-scope="value">
                  <div
                    :class="
                      value.months() + 1 != currentMoth
                        ? 'unceuurentMoth'
                        : 'currentMoth'
                    "
                  >
                    <div
                      :class="{
                        dateClass: true,
                        workborder: getborder(value),
                        selectDay: currentDate == isCurrent(value),
                      }"
                    >
                      {{ value.date() }}
                      <span :class="{ Textactive: getListData(value) }"></span>
                    </div>
                  </div>
                </template>
              </a-calendar>
            </div>
          </div>
          <div class="info-box">
            <div class="info-item">
              <span>生产计划：</span>
              <span>{{ config.data.productionPlan }}</span>
            </div>
            <div class="info-item">
              <span>当前日期：</span>
              <span>{{ currentDate }}</span>
            </div>
            <div class="info-item">
              <span>计划产量件：</span>
              <span>{{ bottom.planComplete }}</span>
            </div>
            <div class="info-item">
              <span>实际产量件：</span>
              <span>{{ bottom.realityComplete }}</span>
            </div>
            <div class="info-item">
              <span>日良品率：</span>
              <span>{{ bottom.dayYield }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "生产日期",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "计划完成数",
    dataIndex: "num1",
    width: 170,
  },
  {
    dataIndex: "age",
    slots: { title: "actuallyTitle" },
    scopedSlots: { customRender: "actually" },
  },
  {
    slots: { title: "BadGoogsTitle" },
    dataIndex: "address",
    scopedSlots: { customRender: "quantity" },
  },
  {
    title: "质检报告",
    dataIndex: "num1",
    width: 170,
    scopedSlots: { customRender: "picture" },
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
import moment from "moment";
import { orderReviewApi } from "@/api/orderReview.js";
// import { loading } from "@/utils/tips.js";
export default {
  name: "trackModel",
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          orderId: "", //当前订单id
          data: "", //当前订单数据
        };
      },
    },
  },
  data() {
    return {
      form: {
        goods_num: "", // 订购数量
        time_delivery: "", //交货日期
      },
      data,
      columns,
      value: moment(new Date(this.config.data.defaultTime)).format(
        "YYYY-MM-DD"
      ),
      mothData: {},
      workRange: [new Date("2016-12-1"), new Date("2017-01-2")],
      currentMoth: new Date().getMonth() + 1, //页面当前月
      currentYear: new Date().getFullYear() + "", //页面当前年
      currentDate: "", //选中的日间
      bottom: {
        planComplete: "无",
        realityComplete: "无",
        dayYield: "无",
      },
    };
  },
  methods: {
    handleCancel() {
      this.config.visible = false;
    },
    closeModel() {
      this.config.visible = false;
    },
    onSelect(value) {
      this.currentMoth = value.months() + 1;
      this.currentYear = value.years();
      this.value = value;
      this.currentDate = moment(+value).format("YYYY-MM-DD");
      // 获取当前时间的数据
      this.getorderDetection();
    },
    onPanelChange(value) {
      this.currentMoth = value.months() + 1;
      this.currentYear = value.years();
      this.value = value;
    },
    getListData(value) {
      // 找到年月日
      let targetYear = this.mothData[value.years()] || {};
      let TagetData = targetYear[value.months() + 1] || [];
      // let moth
      if (TagetData.includes(value.date() + "")) {
        return true;
      } else {
        return false;
      }
    },
    getborder(value) {
      let min = +this.workRange[0];
      let max = +this.workRange[1];
      if (+value > min - 24 * 60 * 60 * 1000 && max >= +value) {
        return true;
      } else {
        return false;
      }
    },
    isCurrent(value) {
      let currentMoth = value.months() + 1 + "";
      currentMoth = currentMoth.padStart(2, "0");
      let currentYear = value.years();
      let days = value.date() + "";
      days = days.padStart(2, "0");
      let str = currentYear + "-" + currentMoth + "-" + days;
      return str;
    },
    // 根据当前时间发送请求
    async getorderDetection() {
      try {
        let data = {
          orderId: this.config.orderId,
          productionData: this.currentDate,
        };
        // loading.show();
        let res = await orderReviewApi.getorderDetection(data);
        // loading.close();
        if (res.success && res.data === null) {
          this.bottom.planComplete = 0;
          this.bottom.realityComplete = 0;
          this.bottom.dayYield = 0 + "%";
          return;
        }
        this.bottom.planComplete = res.data.planComplete;
        this.bottom.realityComplete = res.data.realityComplete;
        this.bottom.dayYield =
          (Number(res.data.dayYield) * 100).toFixed(2) + "%";
      } catch (e) {
        console.log(e);
        // loading.close();
      }
    },
    // 格式话选中时间
    setSignDate() {
      let target = {};
      for (const v of this.config.data.calendars) {
        let arr = v.split("-");
        for (let i = 0; i < arr.length; i++) {
          let item = String(Number(arr[i]));
          var FY;
          let FM;
          if (i === 0) {
            FY = item;
            if (target[FY] === undefined) {
              target[FY] = {};
            }
          } else if (i == 1) {
            FM = String(Number(item));
            if (target[FY][FM] === undefined) {
              target[FY][FM] = [];
            }
          } else if (i == 2) {
            target[FY][String(Number(arr[1]))].push(item);
          }
        }
      }
      this.mothData = target;
      // this.$forceUpdate();
    },
  },
  computed: {},
  created() {
    let target = this.config.data.productionPlan.split("~");
    this.workRange[0] = new Date(target[0]);
    this.workRange[1] = new Date(target[1]);
    this.currentDate = this.config.data.defaultTime;
    // 获取时间数据
    this.getorderDetection();
    this.setSignDate();
  },
};
</script>

<style scoped lang="scss">
.alignment {
  display: flex;
  /deep/.ant-form-item-label {
    width: 100px !important;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.track-box {
  display: flex;
  .track-right {
    padding-left: 20px;
    flex: 1;
    /deep/.ant-fullcalendar-header {
      text-align: left;
      padding: 0 11px 11px 0px;
    }
    .datapick {
      height: 501px;
      /deep/.ant-fullcalendar-tbody {
        border-spacing: 10px 50px;
      }
      /deep/.ant-fullcalendar-header .ant-radio-group {
        display: none;
      }
    }
    .info-box {
      background-color: #3ba0ff;
      display: flex;
      padding: 10px 10px 10px 20px;
      flex-wrap: wrap;
      border-radius: 10px;
      .info-item {
        height: 30px;
        width: 50%;
        color: #fff;
      }
    }
  }
  .track-left {
    // width: 150px;
    display: flex;
    padding-right: 20px;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.24);
  }
  .btn-item {
    margin-right: 10px;
  }
  .twoLine {
    width: 50%;
  }
  .pro-percent {
    margin: 15px;
  }
  .Textactive {
    height: 2px;
    width: 10px;
    background-color: #3ba0ff;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.border {
  border: 1px solid #3ba0ff;
}
.dateClass {
  text-align: center;
  cursor: pointer;
  // box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin: 5px 0px;
  line-height: 40px;
  padding-top: 5px;
}
/deep/.ant-fullcalendar-table {
  width: 556px;
}
.unceuurentMoth {
  color: rgba(0, 0, 0, 0.25);
  .dateClass {
    background-color: rgba(238, 238, 238, 0.39);
  }
  .workborder {
    border: 1px solid rgba(19, 128, 254, 0.28);
    box-sizing: border-box;
    background-color: #fff;
  }
}
.currentMoth {
  .dateClass {
    background-color: #eeeeee;
  }
  .workborder {
    border: 1.5px solid rgba(24, 144, 255, 0.5);
    box-sizing: border-box;
    background-color: #fff;
    &:hover {
      box-shadow: 0px 0px 2px #3ba0ff;
    }
  }
}
.selectDay {
  border: 1px solid rgba(24, 144, 255, 1) !important;
}
</style>
