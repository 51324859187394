<template>
  <div>
    <a-skeleton
      :avatar="{ shape: 'square', size: 120 }"
      :paragraph="{ rows: 4 }"
      :title="false"
      :loading="loading"
    >
      <div v-if="list.length > 0">
        <OrderDetail
          v-for="(item, index) in list"
          :Data="item"
          :key="'1' + index"
        />
      </div>
      <Emptys v-else />
    </a-skeleton>
  </div>
</template>
<script>
import OrderDetail from "@/views/index/components/OrderDetail.vue";
import Emptys from "@/components/Empty.vue";
export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    OrderDetail,
    Emptys,
  },

  mounted() {
    console.log(this.list);
  },

  methods: {},
};
</script>
