<template>
  <div class="good-box">
    <div class="title-info">
      <div class="time">
        <p class="info-item">下单时间: {{ Data.placeOrderTime }}</p>
      </div>
      <div class="time">
        <p class="info-item">订单编号: {{ Data.orderSn }}</p>
      </div>
      <div class="time">
        <p
          class="info-item"
          v-if="Data.orderStatus === 2 && Data.orderTakeState === 1"
        >
          订单预警:
          <span class="normal" v-if="Data.orderWarning === 0">
            <span class="radio"></span>
            正常
          </span>
          <span class="error" v-if="Data.orderWarning === 1">
            <span class="radio"></span>
            异常
          </span>
        </p>
      </div>
      <!-- 状态 -->
      <div class="audit felxright" v-if="Data.orderStatus === 0">审核中</div>
      <div
        class="audit felxright read"
        v-if="Data.orderStatus === 2 && Data.orderTakeState === 0"
      >
        准备中
      </div>
      <div
        class="audit felxright product"
        v-if="Data.orderStatus === 2 && Data.orderTakeState === 1"
      >
        生产中
      </div>
      <div
        class="audit felxright product"
        v-if="Data.orderStatus === 2 && Data.orderTakeState === 2"
      >
        验货中
      </div>
      <div
        class="audit felxright close"
        v-if="Data.orderStatus === 2 && Data.orderTakeState === 3"
      >
        结单
      </div>
      <div class="audit felxright gary" v-if="Data.orderStatus === 1">
        已取消
      </div>
      <div class="audit felxright" v-if="Data.orderStatus === 3">暂存</div>
    </div>
    <div class="contet-info">
      <div>
        <img class="imgs" :src="Data.skuImages" />
      </div>
      <div class="goods-data">
        <h3>{{ Data.goodsName }}</h3>
        <div class="goods-list">
          <div>
            <p class="info-tips">
              <span class="tit">型号：</span
              ><span class="val" :title="Data.skuType">{{ Data.skuType }}</span>
            </p>
            <p class="info-tips">
              <span class="tit">颜色：</span
              ><span class="val" :title="Data.skuColoer">{{
                Data.skuColoer
              }}</span>
            </p>
            <p class="info-tips">
              <span class="tit">订购数量：</span
              ><span class="val" :title="Data.goodsNum">{{
                Data.goodsNum
              }}</span>
            </p>
            <p class="info-tips">
              <span class="tit">基础价(元)：</span>
              <span class="val">{{ Data.onPrice }}</span>
              <!-- <span class="val">{{ Data.onPrice }}</span> -->
            </p>
          </div>
          <div>
            <p class="info-tips">
              <span class="tit">交货时间：</span
              ><span class="val" :title="Data.timeDelivery">{{
                Data.timeDelivery
              }}</span>
            </p>
            <div class="info-tips">
              <span class="tit">交货地点：</span>
              <span class="val" :title="Data.placeDelivery">{{
                Data.placeDelivery
              }}</span>
            </div>
            <p class="info-tips" v-if="Data.orderStatus === 2">
              <span class="tit">合同额：</span
              ><span class="val" :title="Data.contractPrice">{{
                Data.contractPrice
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="felxright">
        <div class="btnbox">
          <a-button
            class="btn"
            type="primary"
            @click="openModal(Data.orderId)"
            v-if="Data.orderStatus === 2 && Data.orderTakeState !== 0"
          >
            订单跟踪
          </a-button>
          <a-button
            class="btn"
            type="primary"
            ghost
            @click="pushDetail(Data)"
          >
            查看选品
          </a-button>
        </div>
      </div>
    </div>

    <trackModel :config="trackModelConfig" v-if="trackModelConfig.visible" />
  </div>
</template>
<script>
import trackModel from "./trackModel.vue";
import { orderReviewApi } from "@/api/orderReview.js";
export default {
  components: {
    trackModel,
  },
  props: {
    Data: {
      type: Object,
    },
  },
  data() {
    return {
      trackModelConfig: {
        visible: false,
        orderId: "", //当前订单id
        data: "", //当前订单数据
      }, // 订单跟踪弹框显示
    };
  },

  mounted() {
    // console.log(this.Data);
  },

  methods: {
    async openModal(id) {
      let res = await orderReviewApi.getDetection(id);
      this.trackModelConfig.orderId = id;
      this.trackModelConfig.data = res.data;
      this.trackModelConfig.visible = true;
    },
    closeModal() {
      this.orderTrackVisible = false;
    },
    pushDetail(data) {
      console.log(data.goodsId, data.orderId);
      // this.$router.push("/detail?id=" + id);
      let url = this.$router.resolve({
        path: `detail?goodsId=${data.goodsId}&orderId=${data.orderId}`,
      });
      window.open(url.href); //注意，需要加href
    },
  },
};
</script>
<style lang="scss" scoped>
p,
h3 {
  margin: 0px;
  padding: 0px;
}
p {
  height: 19px;
  line-height: 19px;
}
.good-box {
  border: 1px solid #ffd4b7;
  margin-bottom: 20px;
  .title-info {
    display: flex;
    height: 32px;
    line-height: 32px;
    align-items: center;
    background-color: #ffe9db;
    box-sizing: border-box;
    padding: 0px 20px;
    .time {
      width: 200px;
    }
    .info-item {
      color: #999999;
      font-size: 14px;
    }
    // .code {
    //   flex: 1;
    // }
  }

  .contet-info {
    display: flex;
    padding: 10px 10px 15px 25px;
    align-items: center;
    .imgs {
      width: 120px;
      height: 120px;
    }
    .goods-data {
      width: 50%;
      padding-left: 20px;
      h3 {
        font-weight: 500;
      }
      .goods-list {
        width: 100%;
        display: flex;
        & > div {
          width: 50%;
        }
        .info-tips {
          width: 30rem;
          color: #aca8a5;
          font-size: 14px;
          // line-height: 30px;
          .tit {
            color: #999999;
          }
          .val {
            display: inline-block;
            color: #666;
            width: 13rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
        }
      }
    }
  }

  .felxright {
    margin-right: 20px;
  }
  .btnbox {
    width: 100px;
    float: right;
    .btn {
      margin-bottom: 10px;
    }
  }
}
.audit {
  color: #ff4400;
}
.read {
  color: #faad14;
}
.product {
  color: #1890ff;
}
.close {
  color: #52c41a;
}
.gary {
  color: #666;
}
.radio {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}
.normal {
  color: #52c41a;
  .radio {
    background: #52c41a;
  }
}
.error {
  color: #ff4d4f;
  .radio {
    background: #ff4d4f;
  }
}
</style>
