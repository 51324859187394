<template>
  <div>
    <Breadcrumb :postion="'我的订单'" />
    <div class="home-container">
      <div class="home-content textleft">
        <a-tabs default-active-key="1" @change="changeTit">
          <a-tab-pane key="1">
            <span slot="tab"
              >全部订单<span>({{ orderNum.total }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
              <!-- <a-skeleton
                :avatar="{ shape: square, size: 120 }"
                :paragraph="{ rows: 4 }"
                :title="false"
                :loading="loading"
              >
                <div v-if="list.length > 0">
                  <OrderDetail
                    v-for="(item, index) in list"
                    :Data="item"
                    :key="'1' + index"
                  />
                </div>
                <Emptys v-else />
              </a-skeleton> -->
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab"
              >审核阶段 <span>({{ orderNum.examine }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="3">
            <span slot="tab"
              >准备阶段 <span>({{ orderNum.prepare }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="4">
            <span slot="tab"
              >生产阶段 <span>({{ orderNum.product }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="5">
            <span slot="tab"
              >验货阶段 <span>({{ orderNum.inspection }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="6">
            <span slot="tab"
              >结单 <span>({{ orderNum.statement }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="7">
            <span slot="tab"
              >取消 <span>({{ orderNum.cancel }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="8">
            <span slot="tab"
              >终止 <span>({{ orderNum.stop }})</span></span
            >
            <div class="body">
              <OrderLoad :loading="loading" :list="list" />
            </div>
          </a-tab-pane>
        </a-tabs>
        <!-- 分页 -->
        <a-pagination
          class="pagination"
          :current="pagination.current"
          :total="pagination.total"
          @change="changes"
          @showSizeChange="onsize"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OrderLoad from "@/components/OrderLoad.vue";
import Breadcrumb from "../components/breadcrumb.vue";
// import Emptys from "@/components/Empty.vue";
import { MallAPI } from "@/api/mall.js";
export default {
  name: "LinkDistanceMallWebOrder",

  data() {
    return {
      loading: true,
      list: [],
      sort: {
        orderStatus: "",
        orderTakeState: "",
        page: 1,
        size: 10,
      },
      pagination: {
        current: 1,
        total: 0,
      },
      orderNum: {
        total: 0, // 全部
        examine: 0, // 审核
        prepare: 0, // 准备
        product: 0, // 生产
        inspection: 0, // 验货
        statement: 0, // 结单
        cancel: 0, // 取消
        stop: 0, // 终止
      },
    };
  },
  components: {
    OrderLoad,
    Breadcrumb,
    // Emptys,
  },

  mounted() {
    this.getData();
  },

  methods: {
    changeTit(key) {
      console.log(key);
      switch (key) {
        case "1":
          this.sort.orderStatus = "";
          this.sort.orderTakeState = "";
          this.getData();
          break;
        case "2":
          this.sort.orderStatus = 0;
          this.sort.orderTakeState = "";
          this.getData();
          break;
        case "3":
          this.sort.orderStatus = "";
          this.sort.orderTakeState = 0;
          this.getData();
          break;
        case "4":
          this.sort.orderStatus = "";
          this.sort.orderTakeState = 1;
          this.getData();
          break;
        case "5":
          this.sort.orderStatus = "";
          this.sort.orderTakeState = 2;
          this.getData();
          break;
        case "6":
          this.sort.orderStatus = "";
          this.sort.orderTakeState = 3;
          this.getData();
          break;
        case "7":
          this.sort.orderStatus = 1;
          this.sort.orderTakeState = "";
          this.getData();
          break;
        case "8":
          this.sort.orderStatus = 4;
          this.sort.orderTakeState = "";
          this.getData();
          break;
      }
    },

    getData() {
      this.loading = true;
      MallAPI.list(this.sort).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.success) {
          this.list = res.data.records;
          this.pagination.total = res.data.total;
        }
      });
    },

    // 拿取订单每个页签数量
    getTabsNum() {
      MallAPI.tabsNum().then((res) => {
        console.log(res.data);
        let i = 0;
        for (let item in this.orderNum) {
          this.orderNum[item] = res.data[i];
          i++;
        }
        console.log(this.orderNum);
      });
    },

    changes(e) {
      this.pagination.current = e;
      this.sort.page = e;
      this.getData();
    },

    onsize() {},
  },
  created() {
    this.getTabsNum();
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  width: 100%;
  position: relative;
  padding: 15px 0px;
  .home-content {
    width: 1200px;
    margin: auto;
    background: #fff;
    padding-bottom: 20px;
    .body {
      padding: 20px;
    }
  }
}
.pagination {
  margin-right: 20px;
}
</style>
